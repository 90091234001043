import { Injectable } from '@angular/core';
import { HesFeedbackComponent } from '@ui-kit/hes-feedback/hes-feedback.component';
import { IFeedback } from '@ui-kit/hes-feedback/feedback.interface';
import { ModalController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private modalCtrl: ModalController) {}

  /**
   * Opens a modal with the specified props and optional callbacks.
   * @param props - The props to pass to the modal component.
   * @param onConfirm - Optional callback function to be called when the modal is confirmed.
   * @param onCancel - Optional callback function to be called when the modal is cancelled.
   * @param backdropDismiss - Optional boolean to enable/disable backdrop dismiss.
   */
  async openFeedbackModal(
    props: IFeedback,
    onConfirm?: () => void,
    onCancel?: () => void,
    backdropDismiss = true,
  ) {
    const modal = await this.modalCtrl?.create({
      component: HesFeedbackComponent,
      cssClass: 'lg-modal',
      backdropDismiss,
      componentProps: {
        ...props,
      },
    });
    modal.present();
    const { role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      onConfirm?.();
      return true;
    }
    if (role === 'cancel') {
      onCancel?.();
    }
    return false;
  }
}
