import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import {
  faCircleCheck,
  faCircleExclamation,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FeedbackType, FaIconComponentsProps } from 'src/app/shared/types';
import { CommonModule } from '@angular/common';
import { HesButtonModule } from '@ui-kit/hes-button/hes-button.module';
@Component({
  selector: 'app-hes-feedback',
  templateUrl: './hes-feedback.component.html',
  styleUrls: ['./hes-feedback.component.scss'],
  standalone: true,
  imports: [HesButtonModule, CommonModule],
})
export class HesFeedbackComponent {
  faCircleCheck = faCircleCheck;
  faCircleExclamation = faCircleExclamation;
  faTriangleExclamation = faTriangleExclamation;

  @Input() type: FeedbackType = 'success';
  @Input({ required: false }) icon: FaIconComponentsProps['icon'] | undefined =
    undefined;
  @Input({ required: true }) modalTitle: string;
  @Input({ required: false }) modalMessage: string | undefined;
  @Input({ required: false }) primaryBtnStr: string | undefined;
  @Input({ required: false }) secondaryBtnStr: string | undefined;

  constructor(private modalCtrl: ModalController) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }
}
